import { createSelector } from "reselect";

const selectSettings = (state) => state.settings;

export const selectCurrentPage = createSelector(
  [selectSettings],
  (settings) => settings.currentPage
);

export const selectCurrentPageTitle = createSelector(
  [selectSettings],
  (settings) => settings.pageTitle
);

export const getNavOpen = createSelector(
  [selectSettings],
  (settings) => settings.navOpen
);

export const getSnackbar = createSelector(
  [selectSettings],
  (settings) => settings.snackbarDetails
);
