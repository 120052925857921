import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material-ui
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";

// Third-party
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { GiSpy } from "react-icons/gi";
import { BsCardChecklist } from "react-icons/bs";
import { ImCopy } from "react-icons/im";
import { HiOutlineClipboardCopy } from "react-icons/hi";

// Project Imports
import MainCard from "components/cards/main-card/main-card.component";
import StatsChart from "components/chart/chart.component";
import { setCurrentPage, setSnackbar } from "redux/settings/settings.actions";
import { setUserName } from "redux/user/user.actions";
import { database } from "firebase/firebase.utils";
import {
  BOOKING_STATUS_REF_LIST,
  convertEpochTimeToDate,
  stableSort,
  getComparator,
} from "utilities/utilities";
import {
  displayQuickDetails,
  displayTextData,
  retrieveLiveBooking,
} from "utilities/network";
import CONFIG from "values/config.js";
import { COLORS } from "values/colors";
import { convertChartAnalysis } from "./live-services.actions";
import { CardIcons, CardStrings } from "values/strings";

// ==============================|| Live Services ||============================== //

function LiveServices({ pageDetails, pageType, setCurrentPage, setSnackbar }) {
  let navigate = useNavigate();
  const theme = useTheme();
  const matchSuperSmall = window.innerWidth <= 320;
  const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));

  // Component variables
  // -> Charts
  const [bookingList, setBookingList] = useState({
    success: false,
    result: "Loading...",
  });
  // -> Sub Card Information
  const [subDetails, setSubDetails] = useState({});

  // ==============================|| Table View ||============================== //

  const TableView = () => {
    // -> Table Filter & pagination variables
    const [filterQuery, setFilterQuery] = React.useState("");
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("availability");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const filteredBookings =
      bookingList && Object.keys(bookingList.result).length !== 0
        ? filterQuery
          ? bookingList.result.filter(
              (booking) =>
                booking.availability.includes(filterQuery) ||
                booking.area_zone.includes(filterQuery) ||
                booking.location_name.includes(filterQuery)
            )
          : bookingList.result
        : [];

    // Table Column data
    const headCells = [
      {
        id: "availability",
        disablePadding: true,
        label: "Availability",
      },
      {
        id: "status_code",
        disablePadding: false,
        label: "Status",
      },
      {
        id: "area_zone",
        disablePadding: false,
        label: "Area Zone",
      },
      {
        id: "location_name",
        disablePadding: false,
        label: "Location",
      },
      {
        id: "last_updated",
        disablePadding: false,
        label: "Last Updated",
      },
    ];

    const useClasses = makeStyles((theme) => ({
      iconContainer: {
        "&:hover": {
          backgroundColor: COLORS.turquoise,
          color: COLORS.white,
        },
      },
    }));
    const classes = useClasses();

    const createSortHandler = (property) => (event) => {
      handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const generateTableActions = (details) => {
      return (
        <>
          <IconButton
            aria-label={CardStrings.VIEW_OWNER}
            title={CardStrings.VIEW_OWNER}
            onClick={async () => {
              const result = await displayQuickDetails(
                navigate,
                "Owner",
                details.owner
              );
              setSubDetails({
                title: CardStrings.VIEW_OWNER_TITLE,
                icon: CardIcons.BOOKINGS,
                details: result.result,
              });
            }}
            classes={{
              root: classes.iconContainer,
            }}
          >
            <SupervisedUserCircleOutlinedIcon />
          </IconButton>
          {details.partner && (
            <IconButton
              aria-label={CardStrings.VIEW_PARTNER}
              title={CardStrings.VIEW_PARTNER}
              onClick={async () => {
                const result = await displayQuickDetails(
                  navigate,
                  "Partner",
                  details.partner
                );
                setSubDetails({
                  title: CardStrings.VIEW_PARTNER_TITLE,
                  icon: CardIcons.BOOKINGS,
                  details: result.result,
                });
              }}
              classes={{
                root: classes.iconContainer,
              }}
            >
              <GiSpy />
            </IconButton>
          )}
          <IconButton
            aria-label={CardStrings.VIEW_BOOKING}
            title={CardStrings.VIEW_BOOKING}
            onClick={() =>
              setSubDetails({
                title: CardStrings.VIEW_BOOKING_TITLE,
                icon: CardIcons.DETAILS,
                details: displayTextData("Booking", details),
              })
            }
            classes={{
              root: classes.iconContainer,
            }}
          >
            <BsCardChecklist />
          </IconButton>
        </>
      );
    };

    return (
      <MainCard
        title="Booking List"
        titleLogo={<CardIcons.BOOKINGS stroke={1.5} size="1.3rem" />}
        sx={{ mt: 2 }}
      >
        <Paper
          variant="outlined"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            mb: 2,
          }}
        >
          <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Filter"
            onChange={(event) => {
              setFilterQuery(event.target.value);
            }}
          />
        </Paper>
        <Paper variant="outlined" sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            sx={{
              width: "400px",
              maxWidth: "100%",
              ...(matchUpSm && { width: "100%" }),
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(filteredBookings, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        key={row.owner}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body2"
                            color={
                              row.availability === "Engaged"
                                ? COLORS.pastelGreen
                                : COLORS.pastelPurple
                            }
                            sx={{ lineHeight: 1.3 }}
                          >
                            {row.availability}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {BOOKING_STATUS_REF_LIST[row.status_code]}
                        </TableCell>
                        <TableCell>{row.area_zone}</TableCell>
                        <TableCell>{row.location_name}</TableCell>
                        <TableCell>
                          {convertEpochTimeToDate(row.last_updated)}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {generateTableActions(row)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredBookings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </MainCard>
    );
  };

  // ==============================|| Main Layout ||============================== //

  const Icon = pageDetails.icon;
  const itemIcon = <Icon stroke={1.5} size="1.3rem" />;

  const fbBookingRef = database.child("settings/console/index/booking");

  // Start-up Task
  useEffect(() => {
    setCurrentPage(pageDetails);
    fbBookingRef.on("value", (snap) => {
      retrieveLiveBooking(navigate, pageType, setBookingList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentPage, pageDetails]);

  // Calculate stats
  const calculateStats = (availableOnly) => {
    var stats = {};
    Object.keys(bookingList.result).forEach((entry) => {
      if (
        availableOnly &&
        bookingList.result[entry].availability === "Engaged"
      ) {
        return;
      }

      if (bookingList.result[entry].area_zone in stats) {
        stats[bookingList.result[entry].area_zone] += 1;
      } else {
        stats[bookingList.result[entry].area_zone] = 1;
      }
    });

    const ordered_stats = Object.keys(stats)
      .sort()
      .reduce((obj, key) => {
        obj[key] = stats[key];
        return obj;
      }, {});

    var stats_message = "";
    for (var area in ordered_stats) {
      var amount = ordered_stats[area];
      stats_message += amount + " " + area + "\n";
    }

    return stats_message;
  };

  return (
    <>
      <MainCard
        title={pageDetails.title}
        titleLogo={itemIcon}
        secondary={
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "32px" }}
          >
            <Grid item>
              <Typography variant="caption" sx={{ lineHeight: 1.3 }}>
                Auto {matchSuperSmall && <br />} Updated
              </Typography>
            </Grid>
          </Grid>
        }
        footer={
          bookingList.success === true && (
            <Stack spacing={1}>
              <div>
                {/* Copy All */}
                <Button
                  variant="contained"
                  startIcon={<ImCopy />}
                  size="small"
                  disableElevation
                  onClick={() => {
                    navigator.clipboard.writeText(calculateStats(false));
                    setSnackbar({
                      title: "Copied to clipboard",
                      type: "success",
                    });
                  }}
                  sx={{ textTransform: "none" }}
                >
                  Copy all bookings
                </Button>
                {/* Copy Available */}
                <Button
                  variant="contained"
                  startIcon={<HiOutlineClipboardCopy />}
                  size="small"
                  disableElevation
                  onClick={() => {
                    navigator.clipboard.writeText(calculateStats(true));
                    setSnackbar({
                      title: "Copied to clipboard",
                      type: "success",
                    });
                  }}
                  sx={{ textTransform: "none", ml: 2 }}
                >
                  Copy available bookings
                </Button>
              </div>
            </Stack>
          )
        }
      >
        <Helmet>
          <title>{pageDetails.title + " | " + CONFIG.baseSiteName}</title>
        </Helmet>
        {bookingList.success === true ? (
          <Grid container direction="row" spacing={0}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ ...(!matchUpSm && { pb: "20px" }) }}
            >
              <StatsChart
                type="single"
                title="Availability Analysis"
                data={convertChartAnalysis(bookingList.stats.availability)}
              />
            </Grid>
            {matchUpSm && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ mr: "-1px", mt: "-15px", mb: "-15px" }}
                />
              </>
            )}
            <Grid item xs={12} sm={6} sx={{ ...(matchUpSm && { pl: "20px" }) }}>
              <StatsChart
                type="breakdown"
                title="Region Breakdown"
                data={convertChartAnalysis(bookingList.stats.region)}
              />
            </Grid>
          </Grid>
        ) : (
          <Typography>{bookingList.result}</Typography>
        )}
      </MainCard>
      {/* Finish Loading */}
      {bookingList.success === true && (
        <>
          <TableView />
          {subDetails && Object.keys(subDetails).length !== 0 && (
            <MainCard
              title={subDetails.title}
              titleLogo={<subDetails.icon stroke={1.5} size="1.3rem" />}
              secondary={
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<CloseIcon />}
                  onClick={() => setSubDetails()}
                >
                  Close
                </Button>
              }
              sx={{ mt: 2 }}
            >
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {subDetails.details}
              </Typography>
            </MainCard>
          )}
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (page) => dispatch(setCurrentPage(page)),
  setUserName: (name) => dispatch(setUserName(name)),
  setSnackbar: (val) => dispatch(setSnackbar(val)),
});

export default connect(null, mapDispatchToProps)(LiveServices);
