import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Material-ui
import {
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import { teal, blue, deepPurple, orange } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";

// Third-party
import swal from "sweetalert";
import {
  AiOutlineCar,
  AiOutlineFolderView,
  AiOutlineBook,
  AiOutlineMail,
} from "react-icons/ai";
import { BiServer } from "react-icons/bi";
import { FaUserSecret } from "react-icons/fa";

// Project Imports
import MainCard from "components/cards/main-card/main-card.component";
import { CustomDialog } from "components/modals/dialogs/custom-dialog";
import { convertEpochTimeToDate, scrollToBottom } from "utilities/utilities";
import { displayQuickDetails, editFeedbackDetails } from "utilities/network";
import { filterFeedbackResult } from "utilities/data";
import { CardIcons } from "values/strings";
import { COLORS } from "values/colors";
import { CardStrings } from "values/strings";
import { setSnackbar } from "redux/settings/settings.actions";

// ==============================|| Feedback Details ||============================== //

export default function FeedbackDetails({
  selectedDetails,
  setDetailsCard,
  setSubCard,
  retrieveFeedback,
}) {
  let navigate = useNavigate();
  // Themes
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));

  // Modals
  const [caseModal, setCaseModal] = useState(false);

  // Button color theme
  const colorTheme = createTheme({
    palette: {
      close: {
        main: COLORS.pastelPink,
        contrastText: "#fff",
      },
      open: {
        main: teal["A700"],
        contrastText: "#fff",
      },
      view: {
        main: COLORS.pastelIndigo,
        contrastText: "#fff",
      },
      booking: {
        main: blue["A200"],
        contrastText: "#fff",
      },
      view_logs: {
        main: deepPurple["500"],
        contrastText: "#fff",
      },
      add_logs: {
        main: orange["400"],
        contrastText: "#fff",
      },
      email: {
        main: COLORS.pastelGreen,
        contrastText: "#fff",
      },
    },
  });

  // Retrieve the relevant report details
  var details = {
    ...selectedDetails,
    ...(selectedDetails.support_type === "Report" && {
      booking_id: selectedDetails.details.substring(12, 29),
    }),
    details:
      selectedDetails.support_type === "Report"
        ? selectedDetails.details.substring(41).slice(0, -1)
        : selectedDetails.details,
  };

  const generateFooterOptions = (details) => {
    return (
      <>
        <ThemeProvider theme={colorTheme}>
          <Stack spacing={1}>
            <div>
              {/* Open/Close Case */}
              <Button
                variant="contained"
                color={details.closed === true ? "open" : "close"}
                startIcon={<BiServer />}
                size="small"
                disableElevation
                onClick={() => setCaseModal(true)}
                sx={{ textTransform: "none" }}
              >
                {details.closed === true ? "Open" : "Close"} Case
              </Button>
              {/* View Requester */}
              <Button
                variant="contained"
                color="view"
                startIcon={<FaUserSecret stroke={1} size="0.9rem" />}
                size="small"
                disableElevation
                onClick={async () => {
                  const result = await displayQuickDetails(
                    navigate,
                    details.creator_type,
                    details.creator
                  );
                  setSubCard({
                    type: "view_details",
                    title: CardStrings.VIEW_OWNER_TITLE,
                    icon: CardIcons.REQUESTER,
                    details: result.result,
                  });
                  setTimeout(() => scrollToBottom(), 50);
                }}
                sx={{ textTransform: "none", ml: 2 }}
              >
                View Requester
              </Button>
              {/* View Reported Booking */}
              {details.hasOwnProperty("booking_id") && (
                <>
                  {!matchUpSm && <br />}
                  <Button
                    variant="contained"
                    color="booking"
                    startIcon={<AiOutlineCar />}
                    size="small"
                    disableElevation
                    onClick={async () => {
                      const result = await displayQuickDetails(
                        navigate,
                        "Booking",
                        details.booking_id
                      );
                      setSubCard({
                        type: "view_details",
                        title: CardStrings.VIEW_OWNER_TITLE,
                        icon: CardIcons.REQUESTER,
                        details: result.result,
                      });
                      setTimeout(() => scrollToBottom(), 50);
                    }}
                    sx={{
                      textTransform: "none",
                      ml: { sm: 2 },
                      mt: { xs: 1, sm: 0 },
                    }}
                  >
                    View Booking
                  </Button>
                </>
              )}
            </div>
            <Divider variant="fullWidth" />
            <div direction="row" spacing={1}>
              {/* View Status Logs */}
              <Button
                variant="contained"
                color="view_logs"
                startIcon={<AiOutlineFolderView />}
                size="small"
                disableElevation
                onClick={() => {
                  if (details.hasOwnProperty("status_log")) {
                    var listContent = "";
                    var firstEntry = true;

                    const status_logs = JSON.parse(details.status_log);
                    Object.keys(status_logs).forEach((index) => {
                      if (firstEntry) {
                        firstEntry = false;
                      } else {
                        listContent = "\n\n".concat(listContent);
                      }
                      listContent = (
                        convertEpochTimeToDate(status_logs[index].time) +
                        "\n" +
                        status_logs[index].text
                      ).concat(listContent);
                    });
                    setSubCard({
                      type: "view_details",
                      title: CardStrings.TITLE_STATUS_LOGS,
                      icon: CardIcons.BOOKINGS,
                      details: listContent,
                    });
                    setTimeout(() => scrollToBottom(), 50);
                  } else {
                    swal(
                      "No Status Logs Found",
                      "Feedback does not have any status logs",
                      "info"
                    );
                  }
                }}
                sx={{ textTransform: "none" }}
              >
                View Status Logs
              </Button>
              {/* Add Log */}
              <Button
                variant="contained"
                color="add_logs"
                startIcon={<AiOutlineBook />}
                size="small"
                disableElevation
                onClick={() => {
                  setSubCard({
                    type: "add_logs",
                    title: CardStrings.TITLE_ADD_LOGS,
                    icon: CardIcons.ADD_LOGS,
                  });
                  setTimeout(() => scrollToBottom(), 50);
                }}
                sx={{ textTransform: "none", ml: 2 }}
              >
                Add Log
              </Button>
              {/* Email Requester */}
              {details.closed === false && (
                <>
                  {!matchUpSm && <br />}
                  <Button
                    variant="contained"
                    color="email"
                    startIcon={<AiOutlineMail />}
                    size="small"
                    disableElevation
                    onClick={() => {
                      setSubCard({
                        type: "send_email",
                        title: CardStrings.TITLE_SEND_EMAIL_FEEDBACK,
                        icon: CardIcons.EMAIL,
                      });
                      setTimeout(() => scrollToBottom(), 50);
                    }}
                    sx={{
                      textTransform: "none",
                      ml: { sm: 2 },
                      mt: { xs: 1, sm: 0 },
                    }}
                  >
                    Email Requester
                  </Button>
                </>
              )}
            </div>
          </Stack>
        </ThemeProvider>
      </>
    );
  };

  return (
    <>
      <MainCard
        title="Feedback Details"
        titleLogo={<CardIcons.FEEDBACK_DETAILS stroke={1.5} size="1.3rem" />}
        secondary={
          <Button
            variant="contained"
            color="error"
            startIcon={<CloseIcon />}
            onClick={() => {
              setDetailsCard({});
              setSubCard({});
            }}
          >
            Close
          </Button>
        }
        sx={{ mt: 2 }}
        footer={generateFooterOptions(details)}
      >
        <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
          {filterFeedbackResult(details)}
        </Typography>
      </MainCard>
      {/* == Dialogs == */}
      {/* Open / Close Case */}
      <CustomDialog
        isOpen={caseModal}
        handleClose={() => setCaseModal(!caseModal)}
        handleConfirm={async () => {
          setCaseModal(!caseModal);
          const inputValue = details.closed === true ? "Open" : "Close";
          const result = await editFeedbackDetails(
            navigate,
            details.id,
            "1",
            inputValue,
            setSnackbar
          );
          if (result.success === true) {
            retrieveFeedback();
          }
        }}
        confirmText="Confirm"
        title={details.closed ? "Re-open Case" : "Close Case"}
      >
        <Typography gutterBottom>
          {details.closed
            ? "Confirm changing feedback status to active?"
            : "Confirm changing feedback status to closed?"}
        </Typography>
      </CustomDialog>
    </>
  );
}
