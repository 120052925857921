// Assets
import { AiOutlineDashboard } from "react-icons/ai";
import { MdOutlineCleanHands } from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";

// Project Imports
import { MenuIdTypes } from "./index.types";

// constant
const icons = { AiOutlineDashboard, MdOutlineCleanHands, VscFeedback };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const LIVE_BOOKINGS = [
  {
    id: "dashboard-booking",
    title: "Live Bookings",
    type: "item",
    url: "/dashboard/all",
    icon: icons.AiOutlineDashboard,
  },
];

const LIVE_SERVICES = [
  {
    id: "dashboard-polishing",
    title: "Live Services",
    type: "item",
    url: "/dashboard/polishing",
    icon: icons.MdOutlineCleanHands,
  },
];

// TODO Removal when port over to admin
const LIVE_FEEDBACK = [
  {
    id: "dashboard-feedback",
    title: "Live Feedback",
    type: "item",
    url: "/dashboard/feedback",
    icon: icons.VscFeedback,
  },
];

export default function dashboard(perms) {
  var modules = [];

  if (perms.contains(MenuIdTypes.LIVE_BOOKING)) {
    modules = modules.concat(LIVE_BOOKINGS);
  }

  if (perms.contains(MenuIdTypes.LIVE_SERVICES)) {
    modules = modules.concat(LIVE_SERVICES);
  }

  if (perms.contains(MenuIdTypes.LIVE_FEEDBACK)) {
    modules = modules.concat(LIVE_FEEDBACK);
  }

  const combinedArray = {
    id: "dashboard",
    title: "Live Dashboard",
    type: "group",
    children: modules,
  };

  return combinedArray;
}

// ==================|| Utility Functions ||======================= //
// eslint-disable-next-line no-extend-native
Array.prototype.contains = function (element) {
  return this.indexOf(element) > -1;
};
