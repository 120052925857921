// Third-party
import swal from "sweetalert";

// Project Imports
import { auth } from "firebase/firebase.utils";

// ==============================|| Misc ||============================== //

export function signOut() {
  auth.signOut();
}

export const scrollToBottom = () => {
  const anchor = document.querySelector("#bottom-anchor");

  if (anchor) {
    anchor.scrollIntoView({
      behavior: "auto",
    });
  }
};

export function convertEpochTimeToDate(date) {
  let myDate = new Date(1000 * date).toLocaleString("en-AU", "Asia/Singapore");
  return myDate;
}

export const filterJSONChild = (inputJSON, query) => {
  return inputJSON.items.reduce(
    (acc, datum) =>
      acc.concat(
        datum.children.filter(
          (child) => child.id.toLowerCase() === query.toLowerCase()
        )
      ),
    []
  );
};

// ====================|| HTTP Status Code Routing ||==================== //

export function handleStatusError(navigate, error) {
  switch (error.status) {
    case 401:
      displayUnauthorized();
      break;
    case 403:
      displayUnapproved(navigate, error.data);
      break;
    case 405:
      handleInvalidReply(error.data);
      break;
    default:
      displayGenericError();
  }
}

// 2xx reply
export function displayGenericError() {
  swal("Oops!", "Unable to perform the task. Kindly try again later.", "error");
}

// Error: 401 (Unauthorized)
export function displayUnauthorized() {
  signOut();
}

// Error: 403 (Unapproved)
export function displayUnapproved(navigate, response) {
  switch (response) {
    case "UNVERIFIED":
      navigate("/unverified");
      break;
    case "MAINTENANCE":
      navigate("/maintenance");
      break;
    case "UNAUTHORIZED":
      swal(
        "Access denied",
        "You are not allowed to perform this action!",
        "error"
      );
      break;
    default:
      break;
  }
}

// Error: 405 (Invalid reply)
export function handleInvalidReply(response) {
  switch (response) {
    case "MALFORMED":
      swal("Malformed Parameters", "Kindly check and retry again.", "error");
      break;
    default:
      swal("Oops!", response, "error");
      break;
  }
}

// ====================|| Utility function for Table ||==================== //

export const BOOKING_STATUS_REF_LIST = {
  0: "Temporary Block",
  1: "Booking Made/Scheduled",
  2: "Booking Cancelled",
  3: "Groomer Accepted Booking",
  4: "Groomer On-route To Location",
  5: "Groomer Arrive At Location",
  6: "Groomer Commence Cleaning",
  7: "Groomer Completed Cleaning",
  8: "Fare Charged Successfully",
  9: "Email Receipt",
  10: "Booking Completed",
};

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
