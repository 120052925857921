import { SettingsActionTypes } from "./settings.types";

const INITIAL_STATE = {
  currentPage: null,
  pageTitle: null,
  navOpen: false,
  snackbarDetails: {
    title: "Title",
    type: "success",
    status: false,
  },
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SettingsActionTypes.SET_PAGE_TITLE:
      return {
        ...state,
        currentPage: action.payload.id,
        pageTitle: action.payload.title,
      };
    case SettingsActionTypes.TOGGLE_NAV_DRAWER:
      return {
        ...state,
        navOpen: !state.navOpen,
      };
    case SettingsActionTypes.SET_NAV_DRAWER:
      return {
        ...state,
        navOpen: action.payload,
      };
    case SettingsActionTypes.SET_SNACKBAR:
      return {
        ...state,
        snackbarDetails: {
          ...state.snackbarDetails,
          title: action.payload.title,
          type: action.payload.type,
          status: true,
        },
      };
    case SettingsActionTypes.DISMISS_SNACKBAR:
      return {
        ...state,
        snackbarDetails: {
          ...state.snackbarDetails,
          status: false,
        },
      };
    default:
      return state;
  }
};

export default settingsReducer;
