import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectCurrentUserName = createSelector(
  [selectUser],
  (user) => user.currentUserName
);

export const selectCurrentUserPerms = createSelector(
  [selectUser],
  (user) => user.currentUserPerms
);
