import axios from 'axios';

// Project Imports
import CONFIG from 'values/config';
import { handleStatusError } from 'utilities/utilities.js';
import { auth } from 'firebase/firebase.utils';

export const getServerStatus = async (navigate, setCallerMessage, setUserName, setUserPerms) => {
  await auth.currentUser.getIdToken().then((idToken) => {
    axios.post(CONFIG.apiURL + "retrieve-banner", {
      "token": idToken
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        switch(res.status) {
          case 201:
            // Server Reply
            let serverReply = res.data
            setCallerMessage("Server status: OK");
            setUserName(serverReply.profile_name);
            setUserPerms(serverReply.access_level);
            break;
          default:
            // Catch all and handle error
            handleStatusError(navigate, res);
        }
      })
      .catch((err) => {
        // Catch all and handle error
        handleStatusError(navigate, err.response);
      });
  });
};
