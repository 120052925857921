import React, { Fragment, useState } from "react";

// Material-ui
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  MenuItem,
  Menu,
  Toolbar,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";

// Third-party
import { FiMenu } from "react-icons/fi";

// Project Imports
import { auth } from "firebase/firebase.utils";
import { COLORS } from "values/colors";

export default function Header({
  title,
  navOpen,
  toggleNavDrawer,
  isLanding,
  profileName,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <AppBar
        position="fixed"
        open={navOpen}
        elevation={1}
        sx={{ background: "white" }}
      >
        <Toolbar sx={{ pr: "24px" }}>
          <Box display="flex" flexGrow={1} alignItems="center">
            <Box
              sx={{
                mr: "25px",
                height: "100%",
                display: { xs: "none", sm: "block" },
              }}
            >
              <img
                src="https://storage.googleapis.com/shiokr-1326.appspot.com/Website/static/img/partner/logo.png"
                alt="header logo"
                height="45px"
              />
            </Box>
            {!isLanding && (
              <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
                <Avatar
                  variant="rounded"
                  onClick={toggleNavDrawer}
                  sx={{
                    transition: "all .2s ease-in-out",
                    background: COLORS.navDarkBlue,
                    color: COLORS.navLightBlue,
                    width: "38px",
                    height: "38px",
                    "&:hover": {
                      background: COLORS.navLightBlue,
                      color: COLORS.navDarkBlue,
                    },
                  }}
                >
                  <FiMenu />
                </Avatar>
              </ButtonBase>
            )}
            <Typography
              component="h1"
              variant="h6"
              align="center"
              sx={{ ml: "15px", color: "black" }}
            >
              {title}
            </Typography>
          </Box>
          {isLanding ? (
            <Button
              variant="outlined"
              onClick={() => {
                auth.signOut();
              }}
            >
              Log Out
            </Button>
          ) : (
            <>
              <Tooltip title="Account settings">
                <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                  <Avatar
                    alt="User Icon"
                    edge="end"
                    src="https://storage.googleapis.com/shiokr-1326.appspot.com/Website/static/img/partner/user.png"
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Toolbar>
      </AppBar>
      {!isLanding && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>Welcome {profileName}</MenuItem>
          <Divider />
          <MenuItem onClick={() => auth.signOut()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      )}
    </Fragment>
  );
}
