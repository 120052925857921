import PropTypes from "prop-types";
import { forwardRef } from "react";

// material-ui
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { COLORS } from "values/colors";
import CONFIG from "values/config";

// ==============================|| Custom Main Card ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow = true,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      secondary = null,
      titleLogo = null,
      sx = {},
      title,
      footer = null,
      ...others
    },
    ref
  ) => {
    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? "1px solid" : "none",
          borderColor: COLORS.paperBorder,
          borderRadius: CONFIG.borderRadius,
          boxShadow: "inherit",
          "&:hover": {
            boxShadow: boxShadow
              ? "0 2px 14px 0 rgb(32 40 45 / 8%)"
              : "inherit",
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        <CardHeader
          avatar={titleLogo}
          sx={{ "& .MuiCardHeader-action": { mr: 0, mt: 0 } }}
          title={<Typography variant="h6">{title}</Typography>}
          action={secondary}
        />

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}

        {/* footer divider */}
        {footer && <Divider />}
        {footer && <CardContent>{footer}</CardContent>}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default MainCard;
