// Assets
import { AiOutlineMail } from "react-icons/ai";
import { BiAtom } from "react-icons/bi";
import { BsCardList, BsJournalCode } from "react-icons/bs";
import { GrCatalog } from "react-icons/gr";
import { HiOutlineUserGroup } from "react-icons/hi";
import { VscFeedback } from "react-icons/vsc";

// constant
const icons = {
  AiOutlineMail,
  BiAtom,
  BsCardList,
  BsJournalCode,
  HiOutlineUserGroup,
  GrCatalog,
  VscFeedback,
};

export const CardStrings = {
  VIEW_OWNER: "View Owner",
  VIEW_OWNER_TITLE: "Owner Details",
  VIEW_PARTNER: "View Partner",
  VIEW_PARTNER_TITLE: "Partner Details",
  VIEW_BOOKING: "View Booking Details",
  VIEW_BOOKING_TITLE: "Booking Details",
  VIEW_FEEDBACK: "View Feedback Details",
  VIEW_FEEDBACK_TITLE: "Feedback Details",
  VIEW_SAME_USER: "View by same user",
  TITLE_STATUS_LOGS: "Status Logs",
  TITLE_ADD_LOGS: "Add Logs",
  TITLE_SEND_EMAIL_FEEDBACK: "Email Requester",
};

export const CardIcons = {
  BOOKINGS: icons.BiAtom,
  DETAILS: icons.BsCardList,
  FEEDBACK: icons.VscFeedback,
  FEEDBACK_DETAILS: icons.BsJournalCode,
  REQUESTER: icons.HiOutlineUserGroup,
  ADD_LOGS: icons.GrCatalog,
  EMAIL: icons.AiOutlineMail,
};
