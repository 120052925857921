import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

const config = {
  apiKey: "AIzaSyBWErX4JZ-peE8AXFatcXbvgW6dpUNOa5I",
  authDomain: "shiokr-partners.firebaseapp.com",
  databaseURL: "https://shiokr-partners.firebaseio.com",
  projectId: "shiokr-partners",
  storageBucket: "shiokr-partners.appspot.com",
  messagingSenderId: "998223667560",
  appId: "1:998223667560:web:21e70b68820906c7c476dd",
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const sessionPersistence = firebase.auth.Auth.Persistence.SESSION;

export default firebase;
export const database = firebase.database().ref();
