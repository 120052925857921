import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

// Material-ui
import { Alert, CssBaseline, Fab, Snackbar, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Third-party
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// Project Imports
import Header from "components/header/header.component";
import SideDrawer from "components/side-drawer/side-drawer.component";
import ScrollTop from "components/scroll-top/scroll-top.component.js";
import {
  toggleNavDrawer,
  setNavDrawer,
  dismissSnackbar,
} from "redux/settings/settings.actions";
import {
  getNavOpen,
  getSnackbar,
  selectCurrentPageTitle,
} from "redux/settings/settings.selectors";
import {
  selectCurrentUserName,
  selectCurrentUserPerms,
} from "redux/user/user.selectors.js";
import config from "values/config.js";
import { MainLayout } from "./dashboard.styles.jsx";

// ==============================|| Dashboard ||============================== //

function Dashboard({
  currentPerms,
  navOpen,
  pageTitle,
  profileName,
  snackbarDetails,
  toggleNavDrawer,
  setNavDrawer,
  dismissSnackbar,
}) {
  const theme = useTheme();
  const matchUpMd = window.innerWidth > 900;

  // Start-up Task
  useEffect(() => {
    if (matchUpMd) {
      setNavDrawer(true);
    }
  }, [matchUpMd, setNavDrawer]);

  return (
    <Stack direction="column" sx={{ height: "100vh", width: "100vw" }}>
      <CssBaseline />
      {/* top header */}
      <Header
        title={pageTitle}
        navOpen={navOpen}
        toggleNavDrawer={toggleNavDrawer}
        isLanding={false}
        profileName={profileName}
      />

      {/* side nav */}
      <SideDrawer
        currentPerms={currentPerms}
        navOpen={navOpen}
        setNavDrawer={setNavDrawer}
        toggleNavDrawer={toggleNavDrawer}
      />

      {/* contents */}
      <MainLayout theme={theme} open={navOpen} config={config}>
        <span id="back-to-top-anchor" height={0} sx={{ minHeight: 0 }} />
        <Outlet />
        <Snackbar
          open={snackbarDetails.status}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={dismissSnackbar}
        >
          <Alert
            onClose={dismissSnackbar}
            severity={snackbarDetails.type}
            sx={{ width: "100%" }}
          >
            {snackbarDetails.title}
          </Alert>
        </Snackbar>
        <ScrollTop>
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
        <span id="bottom-anchor" height={0} sx={{ minHeight: 0 }} />
      </MainLayout>
    </Stack>
  );
}

const mapStateToProps = createStructuredSelector({
  pageTitle: selectCurrentPageTitle,
  currentPerms: selectCurrentUserPerms,
  navOpen: getNavOpen,
  profileName: selectCurrentUserName,
  snackbarDetails: getSnackbar,
});

const mapDispatchToProps = (dispatch) => ({
  toggleNavDrawer: () => dispatch(toggleNavDrawer()),
  setNavDrawer: (val) => dispatch(setNavDrawer(val)),
  dismissSnackbar: () => dispatch(dismissSnackbar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
