import React from "react";

// Material-ui
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// ==============================|| Copyright ||============================== //

export default function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://shiokr.com/" target="_blank">
        Shiokr Private Limited
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}
