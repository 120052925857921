// Third-party
import axios from "axios";

// Project Imports
import { store } from "redux/store";
import CONFIG from "values/config";
import { auth } from "firebase/firebase.utils";
import { filterBookingResult, filterUserResult } from "./data";
import { handleStatusError } from "./utilities";
import { setUserPerms } from "redux/user/user.actions";
// ====================|| General ||==================== //

const checkPerms = (accessLevel) => {
  store.dispatch(setUserPerms(accessLevel));
};

const retrieveQuickDetails = async (navigate, queryType, queryID) => {
  // Types:
  // -> Booking, Feedback, Owner, Partner
  return await auth.currentUser.getIdToken().then((idToken) => {
    return axios
      .post(
        CONFIG.apiURL + "retrieve-quick-details",
        {
          token: idToken,
          queryType: queryType,
          queryID: queryID,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        switch (res.status) {
          case 201:
            // Server Reply
            let serverReply = res.data;
            checkPerms(serverReply.access_level);
            return {
              success: true,
              result: filterUserResult(queryType, serverReply.result),
            };
          default:
            // Catch all and handle error
            handleStatusError(navigate, res);
            return {};
        }
      })
      .catch((err) => {
        if (
          err.response.status === 403 &&
          err.response.data === "NO_SUCH_USER"
        ) {
          return {
            success: false,
          };
        }

        // Catch all and handle error
        handleStatusError(navigate, err.response);
        return {};
      });
  });
};

export const emailUser = async (navigate, details, setSnackbar) => {
  const url = "email-user";
  return await auth.currentUser.getIdToken().then(async (idToken) => {
    return await axios
      .post(
        CONFIG.apiURL + url,
        {
          token: idToken,
          queryID: details.id,
          queryType: details.type,
          title: details.title,
          content: details.content,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        switch (res.status) {
          case 201:
            // Server Reply
            setSnackbar({ title: "Email sent", type: "success" });
            return {
              success: true,
            };
          default:
            // Catch all and handle error
            handleStatusError(navigate, res);
            return {
              success: false,
            };
        }
      })
      .catch((err) => {
        // Catch all and handle error
        handleStatusError(navigate, err.response);
        return {
          success: false,
        };
      });
  });
};

// ====================|| Live Booking & Feedback ||==================== //

export const retrieveLiveBooking = async (navigate, type, setResult) => {
  const url =
    type === "all" ? "retrieve-live-booking" : "retrieve-live-services-booking";
  await auth.currentUser.getIdToken().then((idToken) => {
    axios
      .post(
        CONFIG.apiURL + url,
        {
          token: idToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        switch (res.status) {
          case 201:
            // Server Reply
            let serverReply = res.data;
            checkPerms(serverReply.access_level);
            if (serverReply.result === "Empty") {
              setResult({ success: false, result: "No live booking." });
            } else {
              delete serverReply["access_level"];
              setResult({ ...serverReply, success: true });
            }
            break;
          default:
            // Catch all and handle error
            handleStatusError(navigate, res);
            setResult({ success: false, result: "An error occured." });
        }
      })
      .catch((err) => {
        // Catch all and handle error
        handleStatusError(navigate, err.response);
        setResult({ success: false, result: "An error occured." });
      });
  });
};

export const retrieveLiveFeedback = async (navigate, setResult) => {
  const url = "retrieve-live-feedback";
  await auth.currentUser.getIdToken().then((idToken) => {
    axios
      .post(
        CONFIG.apiURL + url,
        {
          token: idToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        switch (res.status) {
          case 201:
            // Server Reply
            let serverReply = res.data;
            checkPerms(serverReply.access_level);
            if (serverReply.result === "Empty") {
              setResult({ success: false, result: "No pending feedback." });
            } else {
              delete serverReply["access_level"];
              setResult({ ...serverReply, success: true });
            }
            break;
          default:
            // Catch all and handle error
            handleStatusError(navigate, res);
            setResult({ success: false, result: "An error occured." });
        }
      })
      .catch((err) => {
        // Catch all and handle error
        handleStatusError(navigate, err.response);
        setResult({ success: false, result: "An error occured." });
      });
  });
};

export const retrieveFeedbackTemplates = async (navigate, setResult) => {
  const url = "retrieve-feedback-templates";
  await auth.currentUser.getIdToken().then((idToken) => {
    axios
      .post(
        CONFIG.apiURL + url,
        {
          token: idToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        switch (res.status) {
          case 201:
            // Server Reply
            let serverReply = res.data;
            checkPerms(serverReply.access_level);
            setResult(JSON.parse(serverReply["result"]));
            break;
          default:
            // Catch all and handle error
            handleStatusError(navigate, res);
            setResult({ success: false, result: "An error occured." });
        }
      })
      .catch((err) => {
        // Catch all and handle error
        handleStatusError(navigate, err.response);
        setResult({ success: false, result: "An error occured." });
      });
  });
};

// ====================|| Card ||==================== //

export const displayQuickDetails = async (navigate, type, serverData) => {
  if (["Owner", "Partner", "Booking"].indexOf(type) > -1) {
    const reply = await retrieveQuickDetails(navigate, type, serverData);
    return reply;
  }
  return {};
};

export const displayTextData = (type, serverData) => {
  if (type === "Booking") {
    return filterBookingResult(serverData);
  }
  return "";
};

// ====================|| Feedback ||==================== //

export const editFeedbackDetails = async (
  navigate,
  selectedID,
  optionSelected,
  editedValue,
  setSnackbar
) => {
  return await auth.currentUser.getIdToken().then((idToken) => {
    return axios
      .post(
        CONFIG.apiURL + "edit-feedback-details",
        {
          token: idToken,
          selectedID: selectedID,
          optionSelected: optionSelected,
          editedValue: editedValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        switch (res.status) {
          case 201:
            // Server Reply
            let serverReply = res.data;
            checkPerms(serverReply.access_level);
            // Display to user
            if (optionSelected === "2") {
              setSnackbar({ title: "Logs Added", type: "success" });
            }
            return {
              success: true,
              result: serverReply.result,
            };
          default:
            // Catch all and handle error
            handleStatusError(navigate, res);
            return { success: false };
        }
      })
      .catch((err) => {
        if (
          err.response.status === 403 &&
          err.response.data === "NO_SUCH_FEEDBACK"
        ) {
          return {
            success: false,
          };
        }

        // Catch all and handle error
        handleStatusError(navigate, err.response);
        return {};
      });
  });
};
