import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Material-ui
import { Button, CssBaseline, Stack, Typography } from "@mui/material";

// Third-party
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

// Project Imports
import Header from "components/header/header.component";
import MainCard from "components/cards/main-card/main-card.component";
import { setNavDrawer } from "redux/settings/settings.actions";
import config from "values/config.js";

// =========================|| Landing Page ||========================= //

function LandingPage({ errorType, setCurrentPage, setNavDrawer }) {
  let navigate = useNavigate();

  function determineType(errorType) {
    var title, desc, type;
    switch (errorType) {
      case "unverified":
        type = "Unverified";
        title = "Access Denied";
        desc = "You are not authorised to use this platform.";
        break;
      case "maintenance":
        type = "Maintenance";
        title = "System Maintenance";
        desc = "Please check back at a later time.";
        break;
      case "not-found":
        type = "404 Not Found";
        title = "Page Not Found";
        desc = "Page not found";
        break;
      default:
        type = "Error";
        title = "Error";
        desc = "Please check back at a later time.";
        break;
    }
    return { title, desc, type };
  }
  const details = determineType(errorType);

  useEffect(() => {
    setNavDrawer(false);
  }, [errorType, setCurrentPage, setNavDrawer, details]);

  return (
    <Stack direction="column" sx={{ height: "100vh", width: "100vw" }}>
      <CssBaseline />
      <Helmet>
        <title>{details.type + " | " + config.baseSiteName}</title>
      </Helmet>
      <Header
        title={details.type}
        navOpen={null}
        toggleNavDrawer={null}
        isLanding={true}
      />
      <MainCard title={details.title} sx={{ margin: "20px", mt: "80px" }}>
        <Typography>{details.desc}</Typography>
        {details.type === "404 Not Found" && (
          <Button
            variant="contained"
            onClick={() => {
              navigate("/");
            }}
            sx={{ mt: 2 }}
          >
            Return Home
          </Button>
        )}
      </MainCard>
    </Stack>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setNavDrawer: (val) => dispatch(setNavDrawer(val)),
});

export default connect(null, mapDispatchToProps)(LandingPage);
