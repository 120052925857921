import { SettingsActionTypes } from "./settings.types";

export const setCurrentPage = (page) => ({
  type: SettingsActionTypes.SET_PAGE_TITLE,
  payload: page,
});

export const toggleNavDrawer = () => ({
  type: SettingsActionTypes.TOGGLE_NAV_DRAWER,
});

export const setNavDrawer = (val) => ({
  type: SettingsActionTypes.SET_NAV_DRAWER,
  payload: val,
});

export const setSnackbar = (val) => ({
  type: SettingsActionTypes.SET_SNACKBAR,
  payload: val,
});

export const dismissSnackbar = () => ({
  type: SettingsActionTypes.DISMISS_SNACKBAR,
});
