import React, { useState } from "react";

// Material-ui
import {
  Alert,
  Avatar,
  Box,
  Button,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginIcon from "@mui/icons-material/Login";

// Third-party
import validator from "validator";
import { Helmet } from "react-helmet";

// Project Imports
import { auth, sessionPersistence } from "firebase/firebase.utils";
import Copyright from "components/copyright/copyright.component";
import { SignInStringTypes } from "./sign-in.types";

// ==============================|| Sign In ||============================== //

const theme = createTheme();

export default function SignInPage() {
  // States
  const initialErrorState = {
    isError: false,
    msg: "",
    type: "",
  };
  const [error, setError] = useState(initialErrorState);
  const [forgetPasswordToggle, setForgetPasswordToggle] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const toggleDivChange = () => {
    setEmail("");
    setPassword("");
    setError(initialErrorState);
    setForgetPasswordToggle((forgetPasswordToggle) => !forgetPasswordToggle);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate inputs
    if (email === "") {
      setError({
        isError: true,
        msg: SignInStringTypes.INVALID_EMAIL,
        type: "error",
      });
      return;
    } else if (!forgetPasswordToggle && password === "") {
      setError({
        isError: true,
        msg: SignInStringTypes.NO_PASSWORD,
        type: "error",
      });
      return;
    }

    // Validate Email
    if (!validator.isEmail(email)) {
      setError({
        isError: true,
        msg: SignInStringTypes.INVALID_EMAIL,
        type: "error",
      });
      return;
    }

    // Determine action
    if (forgetPasswordToggle) {
      // Forget Password
      try {
        await auth.sendPasswordResetEmail(email);
      } finally {
        setEmail("");
        setForgetPasswordToggle(
          (forgetPasswordToggle) => !forgetPasswordToggle
        );
        setError({
          isError: true,
          msg: SignInStringTypes.FORGET_PASSWORD_SUCCESS_MSG,
          type: "success",
        });
      }
    } else {
      // Login
      auth.setPersistence(sessionPersistence).then(async () => {
        try {
          await auth.signInWithEmailAndPassword(email, password);
          setPassword("");
        } catch (error) {
          setError({
            isError: true,
            msg: SignInStringTypes.INVALID_CREDENTIALS,
            type: "error",
          });
        }
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Login | Shiokr Partner</title>
      </Helmet>
      <Grid container component="main" sx={{ height: "100vh", width: "100vw" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://storage.googleapis.com/shiokr-1326.appspot.com/Website/static/img/partner/landing_bg.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            backgroundImage:
              "url(https://storage.googleapis.com/shiokr-1326.appspot.com/Website/static/img/partner/bg_img.jpeg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="Shiokr Icon"
              src="https://storage.googleapis.com/shiokr-1326.appspot.com/Website/static/img/partner/logo_base.png"
            />
            <Typography
              component="h1"
              variant="h4"
              display="inline"
              noWrap
              sx={{ mt: 2, mb: 1, color: "#37474F" }}
            >
              {forgetPasswordToggle ? "Forget Password" : "Partner Console"}
            </Typography>
            {error.isError ? (
              <Alert severity={error.type} sx={{ width: "100%", mt: 1, mb: 1 }}>
                {error.msg}
              </Alert>
            ) : null}
            {forgetPasswordToggle ? (
              <Grid container component="form" onSubmit={handleSubmit}>
                <Grid item xs={12}>
                  <TextField
                    required
                    margin="normal"
                    fullWidth
                    autoFocus
                    label="Email Address"
                    placeholder="Email address to reset password"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid container item spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      size="large"
                      fullWidth
                      color="error"
                      onClick={toggleDivChange}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                    >
                      Request
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  label="Email Address"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mt: 3, mb: 2 }}
                  startIcon={<LoginIcon />}
                >
                  Sign In
                </Button>
              </Box>
            )}
            {!forgetPasswordToggle && (
              <Grid container item xs>
                <Grid item xs>
                  <Button onClick={toggleDivChange}>Forgot password?</Button>
                </Grid>
              </Grid>
            )}
            <Copyright sx={{ mt: 3 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
