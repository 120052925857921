// Project Imports
import dashboard from "./dashboard";
import home from "./home";
import { MenuIdTypes } from "./index.types";

// ========================|| Menu Items ||======================== //
export default function getMenuItems(currentPerms) {
  const currentFilteredPerms = currentPerms["currentPerms"];
  const modules = [home];

  // Dashboard
  const liveBookingTerms = [
    MenuIdTypes.LIVE_BOOKING,
    MenuIdTypes.LIVE_SERVICES,
    MenuIdTypes.LIVE_FEEDBACK,
  ];
  if (checkContains(currentFilteredPerms, liveBookingTerms)) {
    modules.push(dashboard(currentFilteredPerms));
  }

  // Return Modules
  let details = {
    items: modules,
  };
  return details;
}

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
const checkContains = (haystack, arr) => {
  if (haystack === undefined || haystack.length === 0) {
    return false;
  }

  return arr.some((v) => haystack.includes(v));
};
