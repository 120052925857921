import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material-ui
import { Typography } from "@mui/material";

// Third-party
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { AiOutlineDashboard } from "react-icons/ai";

// Project Imports
import { getServerStatus } from "./home.actions";
import MainCard from "components/cards/main-card/main-card.component";
import { setCurrentPage } from "redux/settings/settings.actions";
import { setUserName, setUserPerms } from "redux/user/user.actions";
import config from "values/config.js";

// ==============================|| Home ||============================== //

function Home({ pageDetails, setCurrentPage, setUserName, setUserPerms }) {
  const [message, setMessage] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    setCurrentPage(pageDetails);
    getServerStatus(navigate, setMessage, setUserName, setUserPerms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDetails]);

  return (
    <MainCard
      title="Groomer Dashboard Access"
      titleLogo={<AiOutlineDashboard stroke={1.5} size="1.3rem" />}
    >
      <Helmet>
        <title>{pageDetails.title + " | " + config.baseSiteName}</title>
      </Helmet>
      <Typography>{message !== "" ? message : "Loading"}</Typography>
    </MainCard>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (page) => dispatch(setCurrentPage(page)),
  setUserName: (name) => dispatch(setUserName(name)),
  setUserPerms: (perms) => dispatch(setUserPerms(perms)),
});

export default connect(null, mapDispatchToProps)(Home);
