import { UserActionTypes } from "./user.types";

const FULL_PERMS = ["live-booking", "live-services", "feedback"];

const INITIAL_STATE = {
  currentUser: null,
  currentUserName: "",
  currentUserPerms: [],
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case UserActionTypes.SET_USER_NAME:
      return {
        ...state,
        currentUserName: action.payload,
      };
    case UserActionTypes.SET_USER_PERMS:
      var new_perms = action.payload;
      if (action.payload.includes("All")) {
        new_perms = FULL_PERMS;
      }

      return {
        ...state,
        currentUserPerms: new_perms,
      };
    default:
      return state;
  }
};

export default userReducer;
