import React from "react";

// Material-ui
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// Project Imports
import MenuList from "./MenuList";
import config from "values/config.js";
import { COLORS_SIDE_DRAWER } from "values/colors";

export default function SideDrawer({
  currentPerms,
  navOpen,
  setNavDrawer,
  toggleNavDrawer,
}) {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const drawer = (
    <React.Fragment>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          <img
            src="https://storage.googleapis.com/shiokr-1326.appspot.com/Website/static/img/partner/logo.png"
            alt="header logo"
            height="45px"
          />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <MenuList currentPerms={currentPerms} />
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList currentPerms={currentPerms} />
        </Box>
      </MobileView>
    </React.Fragment>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? config.drawerWidth : "auto",
      }}
      aria-label="navigation bar"
    >
      <Drawer
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={navOpen}
        onClose={() => (matchUpMd ? setNavDrawer(true) : null)}
        sx={{
          "& .MuiDrawer-paper": {
            width: config.drawerWidth,
            background: COLORS_SIDE_DRAWER.white,
            color: COLORS_SIDE_DRAWER.grey,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "65px",
              borderRight: 1,
              borderColor: COLORS_SIDE_DRAWER.lightGrey,
            },
          },
        }}
        ModalProps={{ keepMounted: true, onBackdropClick: toggleNavDrawer }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
