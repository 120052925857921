import React from "react";

// Third-party
import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// Project Imports
import "./App.css";
import SignInPage from "./pages/sign-in/sign-in.component";
import Dashboard from "./pages/dashboard/dashboard.component";
import Home from "./pages/home/home.component";
import LiveServices from "./pages/live-services/live-services.component";
import LiveFeedback from "./pages/live-feedback/live-feedback.component";
import { auth } from "./firebase/firebase.utils";
import { setCurrentUser } from "./redux/user/user.actions";
import {
  selectCurrentUser,
  selectCurrentUserPerms,
} from "./redux/user/user.selectors";
import getMenuItems from "menu-items/index.js";
import LandingPage from "pages/landing-page/landing-page.component";
import { filterJSONChild } from "utilities/utilities";
import { MenuIdTypes } from "menu-items/index.types";

// ==============================|| APP ||============================== //

class App extends React.Component {
  unsubscribeFromAuth = null;

  componentDidMount() {
    const { setCurrentUser } = this.props;

    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      setCurrentUser(userAuth);
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  render() {
    return (
      <div>
        {this.props.currentUser ? (
          <AuthRoutes currentPerms={this.props.currentPerms} />
        ) : (
          <UnAuthRoutes />
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentPerms: selectCurrentUserPerms,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

export const AuthRoutes = (currentPerms) => {
  const currentFilteredPerms = currentPerms["currentPerms"];
  return (
    <Routes>
      {/* Error Paths */}
      <Route
        path="/error/unverified"
        element={<LandingPage errorType="unverified" />}
      />
      <Route
        path="/error/maintenance"
        element={<LandingPage errorType="maintenance" />}
      />
      <Route
        path="/error/404"
        element={<LandingPage errorType="not-found" />}
      />
      {/* Partner Page */}
      <Route path="/" element={<Dashboard />}>
        {/* Home */}
        <Route
          path="/"
          element={
            <Home
              pageDetails={
                filterJSONChild(getMenuItems(currentPerms), "home")[0]
              }
            />
          }
        />
        {/* Live Dashboard */}
        {currentFilteredPerms.contains(MenuIdTypes.LIVE_BOOKING) && (
          <Route
            path="/dashboard/all"
            element={
              <LiveServices
                pageDetails={
                  filterJSONChild(
                    getMenuItems(currentPerms),
                    "dashboard-booking"
                  )[0]
                }
                pageType="all"
              />
            }
          />
        )}
        {currentFilteredPerms.contains(MenuIdTypes.LIVE_SERVICES) && (
          <Route
            path="/dashboard/polishing"
            element={
              <LiveServices
                pageDetails={
                  filterJSONChild(
                    getMenuItems(currentPerms),
                    "dashboard-polishing"
                  )[0]
                }
                pageType="services"
              />
            }
          />
        )}
        {/* Live Feedback */}
        {currentFilteredPerms.contains(MenuIdTypes.LIVE_FEEDBACK) && (
          <Route
            path="/dashboard/feedback"
            element={
              <LiveFeedback
                pageDetails={
                  filterJSONChild(
                    getMenuItems(currentPerms),
                    "dashboard-feedback"
                  )[0]
                }
              />
            }
          />
        )}
      </Route>
      <Route exact path="/signin" element={<Navigate to="/" />} />
      <Route path="*" element={<Navigate to="/error/404" />} />
    </Routes>
  );
};

export const UnAuthRoutes = () => {
  return (
    <Routes>
      <Route exact path="/signin" element={<SignInPage />} />
      <Route path="*" element={<Navigate to="/signin" />} />
    </Routes>
  );
};

// ==================|| Utility Functions ||======================= //
// eslint-disable-next-line no-extend-native
Array.prototype.contains = function (element) {
  return this.indexOf(element) > -1;
};
