import React, { useState } from "react";

// Material-ui
import { Stack, Typography } from "@mui/material";

// Third-party
import {
  Cell,
  Label,
  Legend,
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
} from "recharts";

// Project Imports
import { COLORS, COLORS_CHART } from "values/colors";

// ==============================|| Charts ||============================== //

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    value,
  } = props;

  return (
    <g>
      <text
        x={cx}
        y={cy - 15}
        dy={8}
        textAnchor="middle"
        className="recharts-text recharts-label"
        fill="#000000"
        dominantBaseline="central"
      >
        <tspan alignmentBaseline="middle" fontSize="14px" fontFamily="Roboto">
          {payload.name}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy + 10}
        dy={8}
        textAnchor="middle"
        className="recharts-text recharts-label"
        fill={COLORS.darkGrey}
        dominantBaseline="central"
      >
        <tspan fontSize="20px" fontFamily="Roboto">
          {value}
        </tspan>
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const StatsChart = ({ title, type, data }) => {
  const matchSuperSmall = window.innerWidth <= 425;
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const renderLegendText = (value, entry) => {
    const { payload } = entry;

    return <span>{payload["value"] + " " + value}</span>;
  };

  const chartHeight =
    (matchSuperSmall ? 280 : 250) + (data.length > 12 ? 60 : 0);

  return (
    <Stack sx={{ width: "100%" }}>
      <Typography
        variant="subtitle1"
        style={{ fontWeight: 600, color: COLORS.textBlack, marginBottom: 10 }}
      >
        {title}
      </Typography>
      <div style={{ height: chartHeight }}>
        <ResponsiveContainer width="100%" height={chartHeight}>
          <PieChart>
            <Legend
              layout="horizontal"
              verticalAlign="bottom"
              align="left"
              formatter={renderLegendText}
            />
            {type === "single" && (
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                dataKey="value"
              >
                <Label
                  value={data.reduce(
                    (totalBookings, bookings) => totalBookings + bookings.value,
                    0
                  )}
                  position="center"
                  style={{ fontSize: 25, fill: COLORS.darkGrey }}
                />
                {data.map((_entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS_CHART[index % COLORS_CHART.length]}
                  />
                ))}
              </Pie>
            )}
            {type === "breakdown" && (
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onPieEnter}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS_CHART[index % COLORS_CHART.length]}
                  />
                ))}
              </Pie>
            )}
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Stack>
  );
};

export default StatsChart;
