import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";

// Third-party
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// project imports
import config from "values/config.js";
import { COLORS_SIDE_DRAWER } from "values/colors";
import {
  selectCurrentPage,
  getNavOpen,
} from "redux/settings/settings.selectors";
import { toggleNavDrawer } from "redux/settings/settings.actions";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level, currentPage, navOpen, toggleNavDrawer }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  let navigate = useNavigate();

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: currentPage === item?.id ? 8 : 6,
        height: currentPage === item?.id ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  const itemHandler = (url) => {
    navigate(url);

    if (!matchUpMd && navOpen) {
      toggleNavDrawer();
    }
  };

  return (
    <ListItemButton
      disabled={item.disabled}
      sx={{
        borderRadius: config.borderRadius,
        mb: 0.5,
        alignItems: "flex-start",
        backgroundColor: level > 1 ? "transparent !important" : "inherit",
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
        "&:hover": {
          backgroundColor: COLORS_SIDE_DRAWER.selectedBg,
          color: COLORS_SIDE_DRAWER.selectedText,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "transparent",
            color: COLORS_SIDE_DRAWER.darkGrey,
          },
        },
        "&.Mui-selected": {
          color: COLORS_SIDE_DRAWER.selectedText,
        },
      }}
      selected={currentPage === item?.id}
      onClick={() => itemHandler(item?.url)}
    >
      <ListItemIcon
        sx={{
          my: "auto",
          minWidth: !item?.icon ? 18 : 36,
          color: "inherit",
        }}
      >
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="body1"
            color="inherit"
            sx={{
              "&:hover": {
                color: COLORS_SIDE_DRAWER.selectedText,
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                  color: COLORS_SIDE_DRAWER.darkGrey,
                },
              },
            }}
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography
              variant="caption"
              sx={{
                fontSize: "0.6875rem",
                fontWeight: 500,
                color: COLORS_SIDE_DRAWER.grey,
                textTransform: "capitalize",
              }}
              display="block"
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  currentPage: selectCurrentPage,
  navOpen: getNavOpen,
});

const mapDispatchToProps = (dispatch) => ({
  toggleNavDrawer: () => dispatch(toggleNavDrawer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavItem);
