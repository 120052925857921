import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material-ui
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableSortLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import { teal } from "@mui/material/colors";
import { visuallyHidden } from "@mui/utils";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";

// Third-party
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { AiOutlineFolderAdd } from "react-icons/ai";
import { ImDisplay } from "react-icons/im";
import { RiMailSendLine } from "react-icons/ri";
import { IoDuplicateOutline } from "react-icons/io5";

// Project Imports
import MainCard from "components/cards/main-card/main-card.component";
import { setCurrentPage, setSnackbar } from "redux/settings/settings.actions";
import { setUserName } from "redux/user/user.actions";
import {
  convertEpochTimeToDate,
  stableSort,
  getComparator,
} from "utilities/utilities";
import {
  editFeedbackDetails,
  emailUser,
  retrieveFeedbackTemplates,
  retrieveLiveFeedback,
} from "utilities/network";
import CONFIG from "values/config.js";
import { COLORS } from "values/colors";
import { CardStrings } from "values/strings";
import FeedbackDetails from "components/cards/feedback-details/feedback-details.component";

// ==============================|| Live Feedback ||============================== //

function LiveFeedback({ pageDetails, setCurrentPage, setSnackbar }) {
  let navigate = useNavigate();
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  // Button color theme
  const colorTheme = createTheme({
    palette: {
      refresh: {
        main: teal["A700"],
        contrastText: "#fff",
      },
    },
  });

  // Component variables
  const [feedbackList, setFeedbackList] = useState({
    success: false,
    result: "Loading...",
  });
  // -> Feedback Template List
  const [templateList, setTemplateList] = useState({});
  // -> Details Card Information
  const [detailsCard, setDetailsCard] = useState({});
  // -> Sub Card Information
  const [subCard, setSubCard] = useState({});

  // -> Table Filter & pagination variables
  const [filterQuery, setFilterQuery] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("time_submitted");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const filteredBookings =
    feedbackList && Object.keys(feedbackList.result).length !== 0
      ? filterQuery
        ? feedbackList.result.filter(
            (booking) =>
              booking.id.includes(filterQuery) ||
              booking.support_type.includes(filterQuery) ||
              booking.creator.includes(filterQuery)
          )
        : feedbackList.result
      : [];

  // ==============================|| Table View ||============================== //

  const TableView = () => {
    // Table Column data
    const headCells = [
      {
        id: "id",
        disablePadding: true,
        label: "Feedback ID",
      },
      {
        id: "support_type",
        disablePadding: false,
        label: "Type",
      },
      {
        id: "time_submitted",
        disablePadding: false,
        label: "Submitted Date",
      },
    ];

    const useClasses = makeStyles((theme) => ({
      iconContainer: {
        "&:hover": {
          backgroundColor: COLORS.turquoise,
          color: COLORS.white,
        },
      },
    }));
    const classes = useClasses();

    const createSortHandler = (property) => (event) => {
      handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const generateTableActions = (details) => {
      return (
        <>
          <IconButton
            aria-label={CardStrings.VIEW_FEEDBACK}
            title={CardStrings.VIEW_FEEDBACK}
            onClick={() => {
              setDetailsCard(details);
              setSubCard({});
            }}
            classes={{
              root: classes.iconContainer,
            }}
          >
            <PageviewOutlinedIcon />
          </IconButton>
          <IconButton
            aria-label={CardStrings.VIEW_SAME_USER}
            title={CardStrings.VIEW_SAME_USER}
            onClick={() => setFilterQuery(details.creator)}
            classes={{
              root: classes.iconContainer,
            }}
          >
            <IoDuplicateOutline />
          </IconButton>
        </>
      );
    };

    return (
      <>
        <Paper
          variant="outlined"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            mb: 2,
          }}
        >
          <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            autoFocus
            sx={{ ml: 1, flex: 1 }}
            placeholder="Filter"
            value={filterQuery}
            onChange={(event) => {
              setFilterQuery(event.target.value);
            }}
          />
          {filterQuery.length !== 0 && (
            <IconButton
              sx={{ p: "10px" }}
              aria-label="clear filter"
              onClick={() => setFilterQuery("")}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Paper>
        <Paper variant="outlined" sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            sx={{
              width: "400px",
              maxWidth: "100%",
              ...(matchUpSm && { width: "100%" }),
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(filteredBookings, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.id}</TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            color={
                              row.support_type === "Report"
                                ? COLORS.pastelRed
                                : row.support_type === "Feedback"
                                ? COLORS.yellow
                                : COLORS.pastelPurple
                            }
                            sx={{ lineHeight: 1.3 }}
                          >
                            {row.support_type}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {convertEpochTimeToDate(row.time_submitted)}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {generateTableActions(row)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25]}
            component="div"
            count={filteredBookings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </>
    );
  };

  // ==============================|| Sub Card Layout ||========================== //

  const SubCardLayout = ({ type }) => {
    if (type === "view_details") {
      return (
        <MainCard
          title={subCard.title}
          titleLogo={<subCard.icon size="1.3rem" />}
          secondary={
            <Button
              variant="contained"
              color="error"
              startIcon={<CloseIcon />}
              onClick={() => setSubCard({})}
            >
              Close
            </Button>
          }
          sx={{ mt: 2 }}
        >
          <Paper elevation={0} style={{ maxHeight: 400, overflow: "auto" }}>
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {subCard.details}
            </Typography>
          </Paper>
        </MainCard>
      );
    } else if (type === "add_logs") {
      return <AddLogCard />;
    } else if (type === "send_email") {
      return <SendEmailCard />;
    } else {
      return <></>;
    }
  };

  const AddLogCard = () => {
    const [subCardInput, setSubCardInput] = React.useState("");
    const [inputLabel, setInputLabel] = React.useState("Log Details");
    const [textError, setTextError] = React.useState(false);

    return (
      <MainCard
        title={subCard.title}
        titleLogo={<subCard.icon size="1.3rem" />}
        secondary={
          <Button
            variant="contained"
            color="error"
            startIcon={<CloseIcon />}
            onClick={() => setSubCard({})}
          >
            Close
          </Button>
        }
        footer={
          <Button
            disableElevation
            variant="contained"
            startIcon={<AiOutlineFolderAdd />}
            onClick={async () => {
              if (subCardInput.length === 0) {
                setTextError(true);
                setInputLabel("Key in the required logs");
              } else {
                const result = await editFeedbackDetails(
                  navigate,
                  detailsCard.id,
                  "2",
                  subCardInput,
                  setSnackbar
                );
                // Clear card
                setSubCard({});

                // Update Details
                const index = feedbackList.result.findIndex(
                    (feedback) => feedback.id === detailsCard.id
                  ),
                  updatedFeedback = [...feedbackList.result];
                updatedFeedback[index] = result.result;
                setFeedbackList({
                  ...feedbackList,
                  result: updatedFeedback,
                });
                setDetailsCard(result.result);
              }
            }}
            sx={{ textTransform: "none" }}
          >
            Add Log
          </Button>
        }
        sx={{ mt: 2 }}
      >
        <Typography variant="body2" sx={{ whiteSpace: "pre-line", mb: 2 }}>
          Input updates for case
        </Typography>
        <TextField
          error={textError}
          multiline
          fullWidth
          label={inputLabel}
          variant="outlined"
          value={subCardInput}
          onChange={(event) => setSubCardInput(event.target.value)}
        />
      </MainCard>
    );
  };

  const SendEmailCard = () => {
    const [emailTemplate, setEmailTemplate] = React.useState("");
    const [emailTitle, setEmailTitle] = React.useState(
      "Enquiry: " + detailsCard.id
    );
    const [emailContent, setEmailContent] = React.useState("");
    const [textError, setTextError] = React.useState(false);

    return (
      <MainCard
        title={subCard.title}
        titleLogo={<subCard.icon size="1.3rem" />}
        secondary={
          <Button
            variant="contained"
            color="error"
            startIcon={<CloseIcon />}
            onClick={() => setSubCard({})}
          >
            Close
          </Button>
        }
        footer={
          <Button
            disableElevation
            variant="contained"
            startIcon={<RiMailSendLine />}
            onClick={async () => {
              if (emailTitle.length === 0 || emailContent.length === 0) {
                setTextError(true);
              } else {
                // Replace all newline with html newline
                var modifiedContent = emailContent.replace(/\n/g, "<br/>");
                const result = await emailUser(
                  navigate,
                  {
                    id: detailsCard.id,
                    type: "Feedback",
                    title: emailTitle,
                    content: modifiedContent,
                  },
                  setSnackbar
                );
                if (result.success === true) {
                  // Clear card
                  setSubCard({});
                }
              }
            }}
            sx={{ textTransform: "none" }}
          >
            Send Email
          </Button>
        }
        sx={{ mt: 2 }}
      >
        {/* Enquiry Details */}
        <Stack direction="row">
          <Typography variant="body2" sx={{ fontWeight: 500, display: "flex" }}>
            Enquiry ID:&nbsp;
          </Typography>
          <Typography variant="body2" sx={{ color: "blue", display: "flex" }}>
            {detailsCard.id}
          </Typography>
        </Stack>
        {/* Divider */}
        <Divider variant="fullWidth" sx={{ mb: 2, mt: 2 }} />
        {/* Template Selection */}
        <Typography variant="body2" sx={{ display: "flex", mb: 1.5 }}>
          Select Template
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
          <FormControl size="small" sx={{ minWidth: 150 }}>
            <InputLabel id="email-select-label">Template</InputLabel>
            <Select
              labelId="email-select-label"
              id="email-requester-select"
              value={emailTemplate}
              label="Template"
              autoWidth
              onChange={(e) => setEmailTemplate(e.target.value)}
            >
              {Object.keys(templateList).map((key, i) => (
                <MenuItem key={i} value={key} sx={{ minWidth: 150 }}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            startIcon={<ImDisplay />}
            onClick={() => {
              if (emailTemplate.length === 0) {
                setEmailContent("");
              } else {
                setEmailContent(templateList[emailTemplate]);
              }
            }}
          >
            Display
          </Button>
        </Stack>
        {/* Divider */}
        <Divider variant="fullWidth" sx={{ mb: 2, mt: 2 }} />
        {/* Email Contents */}
        {textError && (
          <Typography
            variant="body2"
            color="error"
            sx={{ whiteSpace: "pre-line", mb: 1 }}
          >
            Please input a proper email title and content
          </Typography>
        )}
        <Typography variant="body2" sx={{ whiteSpace: "pre-line", mb: 1 }}>
          Title
        </Typography>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          value={emailTitle}
          onChange={(event) => setEmailTitle(event.target.value)}
        />
        <Typography
          variant="body2"
          sx={{ whiteSpace: "pre-line", mb: 1, mt: 2 }}
        >
          Content
        </Typography>
        <TextField
          multiline
          fullWidth
          placeholder="Salutation will automatically be generated. You can directly key in your content here."
          variant="outlined"
          value={emailContent}
          onChange={(event) => setEmailContent(event.target.value)}
        />
      </MainCard>
    );
  };

  // ==============================|| Main Layout ||============================== //

  const itemIcon = <pageDetails.icon stroke={1.5} size="1.3rem" />;

  // Retrieve live feedback
  const retrieveFeedback = async () => {
    // Retrieve Feedback List
    await retrieveLiveFeedback(navigate, setFeedbackList).then(() => {
      setDetailsCard({});
      setSubCard({});
    });
  };

  // Retrieve feedback templates
  const retrieveFeedbackTemplate = () => {
    // Retrieve Feedback Templates List
    retrieveFeedbackTemplates(navigate, setTemplateList);
  };

  // Start-up Task
  useEffect(() => {
    setCurrentPage(pageDetails);
    retrieveFeedback();
    retrieveFeedbackTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentPage, pageDetails]);

  return (
    <>
      <MainCard
        title={pageDetails.title}
        titleLogo={itemIcon}
        secondary={
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "32px" }}
          >
            <Grid item>
              <ThemeProvider theme={colorTheme}>
                <Button
                  variant="contained"
                  color="refresh"
                  startIcon={<CachedOutlinedIcon />}
                  onClick={() => retrieveFeedback()}
                >
                  Refresh
                </Button>
              </ThemeProvider>
            </Grid>
          </Grid>
        }
      >
        <Helmet>
          <title>{pageDetails.title + " | " + CONFIG.baseSiteName}</title>
        </Helmet>
        {feedbackList.success === true ? (
          <TableView />
        ) : (
          <Typography>{feedbackList.result}</Typography>
        )}
      </MainCard>
      {/* Details Card */}
      {feedbackList.success === true &&
        detailsCard &&
        Object.keys(detailsCard).length !== 0 && (
          <>
            <FeedbackDetails
              selectedDetails={detailsCard}
              setDetailsCard={setDetailsCard}
              setSubCard={setSubCard}
              retrieveFeedback={retrieveFeedback}
            />
            {/* Sub Card */}
            {subCard && Object.keys(subCard).length !== 0 && (
              <SubCardLayout type={subCard.type} />
            )}
          </>
        )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (page) => dispatch(setCurrentPage(page)),
  setUserName: (name) => dispatch(setUserName(name)),
  setSnackbar: (val) => dispatch(setSnackbar(val)),
});

export default connect(null, mapDispatchToProps)(LiveFeedback);
