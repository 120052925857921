// assets
import { BiHomeSmile } from "react-icons/bi";

// constant
const icons = { BiHomeSmile };

// ==============================|| MENU ITEMS ||============================== //

const home = {
  id: "main-home",
  type: "group",
  children: [
    {
      id: "home",
      title: "Home",
      type: "item",
      url: "/",
      icon: icons.BiHomeSmile,
    },
  ],
};

export default home;
