import React from "react";

// Material-UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

// Third Party
import PropTypes from "prop-types";

// Project Imports
import { COLORS } from "values/colors";

// ==============================|| Custom Dialog ||============================== //

export const CustomDialog = ({
  isOpen,
  handleClose,
  handleConfirm,
  confirmText,
  title,
  children,
}) => {
  // Button color theme
  const colorTheme = createTheme({
    palette: {
      close: {
        main: "#333",
      },
      confirm: {
        main: COLORS.pastelIndigo,
        contrastText: "#fff",
      },
    },
  });

  return (
    <ThemeProvider theme={colorTheme}>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="custom-dialog"
      >
        <DialogTitle id="custom-dialog">
          {title}
          <IconButton
            aria-label="close-dialog"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          {handleClose && (
            <Button
              autoFocus
              disableElevation
              variant="outlined"
              color="close"
              onClick={handleClose}
              sx={{ textTransform: "none" }}
            >
              Close
            </Button>
          )}
          <Button
            disableElevation
            variant="contained"
            color="confirm"
            onClick={handleConfirm}
            sx={{ textTransform: "none" }}
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

CustomDialog.protoTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};
