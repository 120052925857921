import { BOOKING_STATUS_REF_LIST, convertEpochTimeToDate } from "./utilities";

// ====================|| Data handling function for Card ||==================== //

// -> Based from server JSON, return a string containing vital booking information
export const filterBookingResult = (content) => {
  var quickContent = "";
  if (content.hasOwnProperty("id")) {
    quickContent = quickContent.concat("Booking ID: " + content.id + "\n");
  }
  quickContent = quickContent.concat(
    "Status: " +
      BOOKING_STATUS_REF_LIST[content.status_code] +
      "\nFare: $" +
      content.fare
  );
  if (content.hasOwnProperty("promo_code")) {
    quickContent = quickContent.concat("\nPromo Code: " + content.promo_code);
  }
  quickContent = quickContent.concat(
    "\n\nVehicle Details:\n" + content.vehicle_details.replaceAll("\n", "\n")
  );
  quickContent = quickContent.concat(
    "\n\nArea Zone: " +
      content.area_zone +
      "\nLocation Type: " +
      content.location_type +
      "\nLocation: " +
      content.location_name
  );
  if (content.hasOwnProperty("lot_no")) {
    quickContent = quickContent.concat("\nLot No: " + content.lot_no);
  }
  quickContent = quickContent.concat(
    "\n\nStart Time: " + convertEpochTimeToDate(content.time_requested)
  );
  if (content.hasOwnProperty("time_start")) {
    quickContent = quickContent.concat(
      "\nAccepted Time: " +
        convertEpochTimeToDate(content.time_start) +
        "\nEnd Time: " +
        convertEpochTimeToDate(content.time_end)
    );
  }
  if (content.hasOwnProperty("notes")) {
    quickContent = quickContent.concat("\n\nNotes:\n" + content.notes);
  }
  return quickContent;
};

// -> Based from server JSON, return a string containing vital feedback information
export const filterFeedbackResult = (content) => {
  // ID & Status
  var quickContent =
    "Feedback ID: " +
    content.id +
    "\nStatus: " +
    (content.closed === true ? "Closed" : "Active");
  // Feedback Type & Booking ID
  quickContent = quickContent.concat("\nType: " + content.support_type);
  content.hasOwnProperty("booking_id") &&
    (quickContent = quickContent.concat(
      "\nReported Booking ID: " + content.booking_id
    ));
  // Requester details
  quickContent = quickContent.concat(
    "\n\nRequester: " +
      content.creator +
      "\nRequester Type: " +
      content.creator_type +
      "\nPlatform: " +
      content.platform
  );
  // Submitted Time
  quickContent = quickContent.concat(
    "\n\nTime Submitted: " + convertEpochTimeToDate(content.time_submitted)
  );
  // Closed Time
  if (content.hasOwnProperty("time_closed")) {
    quickContent = quickContent.concat(
      "\nTime Closed: " + convertEpochTimeToDate(content.time_closed)
    );
  }
  // Feedback Details
  quickContent = quickContent.concat("\n\nDetails:\n " + content.details);

  return quickContent;
};

// -> Based from server JSON, return a string containing vital user information
export const filterUserResult = (userType, details) => {
  var quickContent =
    "Email: " +
    details.email +
    "\nCreated on: " +
    convertEpochTimeToDate(details.created) +
    "\nLast Login: " +
    details.last_login +
    "\n\nProfile Name: " +
    details.profile_name;
  if (userType === "Owner") {
    quickContent = quickContent
      .concat("\nContact Number: " + details.contact)
      .concat(
        "\n\nEnabled: " + details.enabled + "\nVerified: " + details.verified
      );
  } else if (userType === "Partner") {
    quickContent = quickContent
      .concat("\nContact Number: " + details.contact)
      .concat(
        "\n\nApproved: " +
          details.approved +
          "\nFull Time: " +
          details.full_time +
          "\n\nWash Balance: " +
          details.wash_balance
      );
  }
  return quickContent;
};
