import { Box } from "@mui/system";
import styled from "styled-components";
import { COLORS } from "values/colors";

export const MainLayout = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, config }) => ({
  backgroundColor: COLORS.paperBG,
  minHeight: "calc(100vh - 90px)",
  flexGrow: 1,
  padding: "20px",
  marginTop: "80px",
  marginRight: "20px",
  marginBottom: "30px",
  display: "table",
  borderRadius: config.borderRadius,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100vw - 40px)`,
    [theme.breakpoints.up("md")]: {
      marginLeft: config.drawerWidth + 10,
      width: `calc(100% - ${config.drawerWidth}px - 20px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "68px",
      marginLeft: "10px",
      marginBottom: "10px",
      width: "calc(100vw - 20px)",
    },
  }),
  ...(!open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: "calc(100vw - 40px)",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "68px",
      marginLeft: "10px",
      marginBottom: "10px",
      width: "calc(100vw - 20px)",
    },
  }),
}));
