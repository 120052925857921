const CONFIG = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  basename: "",
  baseSiteName: "Shiokr Partner",
  apiURL: "https://partner.shiokr.com/api/",
  // apiURL: "https://future-dot-shiokr-1326.appspot.com/api/",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: "12px",
  drawerWidth: 250,
  gridSpacing: 3,
};

export default CONFIG;
