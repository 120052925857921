import { grey } from "@mui/material/colors";

export const COLORS = {
  shiokrBlue: "#40537D",
  white: "#FFFFFF",
  paperBG: "#E2EFFB",
  paperBorder: "#90caf9",
  navDarkBlue: "#DFF0FC",
  navLightBlue: "#204E80",
  textBlack: "#575962",
  darkGrey: "#6C6C6C",
  pastelPurple: "#706AC4",
  pastelGreen: "#61BCA4",
  pastelRed: "#E66E64",
  pastelPink: "#E25D6F",
  pastelIndigo: "#5B67D6",
  turquoise: "#59C2D9",
  yellow: "#FFBB28",
};

export const COLORS_SIDE_DRAWER = {
  selectedBg: COLORS.paperBG,
  selectedText: COLORS.shiokrBlue,
  white: COLORS.white,
  grey: grey[700],
  lightGrey: grey[200],
  darkGrey: COLORS.darkGrey,
};

export const COLORS_CHART = [
  COLORS.pastelPurple,
  "#00C49F",
  COLORS.yellow,
  COLORS.turquoise,
];
